import React, { useState } from "react"

// Context
import Context from "../context/context"

interface IProps {
  children: React.ReactNode
}

const AppWrapper = ({ children }: IProps) => {
  const [party, setParty] = useState<boolean>(false)

  return (
    <Context.Provider
      value={{
        party,
        updateParty: (bool: boolean) => setParty(bool),
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default AppWrapper
